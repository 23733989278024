import { ref, watch, computed } from '@vue/composition-api'
import { title } from '@core/utils/filter'

// Notification
import Vue from 'vue'
import axios from '@axios'
import store from '@/store'
import { useToast } from "vue-toastification/composition";

export default function useServicesList() {
  // Use toast
  const toast = useToast();
  const refServiceListTable = ref(null)
  const reftransferListTable = ref(null)
  const mainServiceList = ref('')
  const mainServiceOption = ref([])
  // Table Handlers
  const tableColumnsBen = [
    { key: 'id', label: ' الرقم', formatter: title },
    { key: 'first_name', label: 'الاسم', formatter: title },
    { key: 'last_name', label: 'النسبة', formatter: title },

  ]
  const tableColumns = [

    { key: 'id', label: ' الرقم', formatter: title },
    { key: 'first_name', label: 'الاسم', formatter: title },
    { key: 'last_name', label: 'النسبة', formatter: title },
    // { key: 'service_status', label: 'الحالة', formatter: title },

    {
      key: 'created_by',
      label: 'الموظف',
      formatter: title,

    },
    {
      key: 'deactive_reason',
      label: 'حالة الشخص',
      formatter: title,

    },
    { key: 'other_contact_details', label: 'ارقام الهواتف ' },

    { key: 'actions', label: 'الخيارات' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const totalFamilies = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const gender = ref(null)
  const familyStatus = ref(null)
  const city = ref(null)
  const town = ref(null)
  const street = ref(null)
  const neighborhood = ref(null)
  const multi_weakness=ref([]);
  const startDate = ref(null)
  const endDate = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const serviceFilter = ref(null)
  const mainService = ref(null)
  const min_age = ref(null)
  const search = ref(null)

  const max_age = ref(null)
  const datatest = ref([])
  const datastatistics = ref([])
  const ExcelDone = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refServiceListTable.value
      ? refServiceListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refServiceListTable.value.refresh()
  }

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      startDate,
      city,
      town,
      neighborhood,
      street,
      multi_weakness,
      endDate,
      gender,
      familyStatus,
      serviceFilter,
      mainService,
      min_age,
      search,
      max_age,
      planFilter,
      statusFilter,
      

    ],
    () => {
      refetchData()
    },
  )
  const requestStatus = id => {
    store
      .dispatch('app-services/requestStatus', {
        id,

      })
      .then(response => {
        Vue.swal({
          title: 'تم طلب بدء الحالة',

          icon: 'success',
          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        refetchData()
      })
      .catch(() => {
        Vue.swal({
          title: `${response.data.message}`,
          icon: 'error',

          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
  }
  const changeStatus = (id, statusid) => {
    // console.log(id)
    Vue.swal({
      title: '  هل متأكد من تغيير الحالة  ؟',
      icon: 'warning',

      confirmButtonText: 'موافق',
      customClass: {
        confirmButton: 'btn btn-primary',
      },
      buttonsStyling: false,
    }).then(result => {
      if (result.value) {
        store
          .dispatch('app-services/changeStatus', {
            id,
            statusid,
          })
          .then(response => {
            Vue.swal({
              title: `${response.data.message}`,

              icon: 'success',
              confirmButtonText: 'موافق',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            refetchData()
          })
          .catch(() => {
            Vue.swal({
              title: 'حدثت مشكلة في استرجاع البيانات',
              icon: 'error',

              confirmButtonText: 'موافق',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
      }
    })
  }




  
  const openStatus = id => {
    store
      .dispatch('app-services/OpenStatus', {
        id,
      })
      .then(response => {
        refetchData()
      })
      .catch(() => {
        Vue.swal({
          title: 'حدثت مشكلة في استرجاع البيانات',
          icon: 'error',

          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
  }
  const fileExcel = async () => {
    Vue.swal({
      title: 'الرجاء الانتظار الى حين استكمال كل البيانات',
      icon: 'success',

      confirmButtonText: 'موافق',
      allowEscapeKey: false,

      allowOutsideClick: false,
      customClass: {
        confirmButton: 'btn btn-primary',
      },
      buttonsStyling: false,
    })
    await store
      .dispatch('app-services/fetchAll', {
        gender: gender.value,
        familyStatus: familyStatus.value,
        city: city.value,
        town: town.value,
        neighborhood: neighborhood.value,
        street:street.value,
        multi_weakness:multi_weakness.value,
        minDateReg: startDate.value,
        maxDateReg: endDate.value,
        service_status: statusFilter.value,
        service: serviceFilter.value,
        main_service: mainService.value,
        search: search.value,
        min_age: min_age.value,
        max_age: max_age.value,
        page: currentPage.value,
      })
      .then(response => {
        // const { data ,total} = response.data.data;
        datatest.value = response.data.data
        // console.log(response.data.data)
        Vue.swal({
          title: 'تم جلب جميع البيانات',
          icon: 'success',
          allowEscapeKey: false,

          allowOutsideClick: false,
          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'حدثت مشكلة في استرجاع البيانات',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const fetchstatistics = async () => {
    // console.log(endDate.value)
    await store
      .dispatch('app-services/fetchstatistics', {
        gender: gender.value,
        familyStatus: familyStatus.value,
        city: city.value,
        town: town.value,
        neighborhood: neighborhood.value,
        street:street.value,
        multi_weakness:multi_weakness.value,
        minDateReg: startDate.value,
        maxDateReg: endDate.value,
        service_status: statusFilter.value,
        service: serviceFilter.value,
        main_service: mainService.value,
        search: search.value,
        min_age: min_age.value,
        max_age: max_age.value,

        page: currentPage.value,
      })
      .then(response => {
        // const { data ,total} = response.data.data;
        datastatistics.value = response.data.data
        // console.log(response.data.data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'حدثت مشكلة في استرجاع البيانات',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const fetchBeneficiary = (ctx, callback) => {
    store
      .dispatch('app-services/fetchBeneficiary', {
        gender: gender.value,
        familyStatus: familyStatus.value,
        city: city.value,
        town: town.value,
        neighborhood: neighborhood.value,
        street:street.value,
        multi_weakness:multi_weakness.value,
        minDateReg: startDate.value,
        maxDateReg: endDate.value,
        service_status: statusFilter.value,
        service: serviceFilter.value,
        main_service: mainService.value,
        search: search.value,
        min_age: min_age.value,
        max_age: max_age.value,
        page_size: perPage.value,

        page: currentPage.value,
      })
      .then(response => {
        // console.log(response.data)
        const { data, total, family_count } = response.data
        callback(data)
        totalUsers.value = total
        totalFamilies.value = family_count
      })
      .catch(() => {
        Vue.swal({
          title: 'حدثت مشكلة في استرجاع البيانات',
          icon: 'error',

          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
  }
  const fetchAll = (ctx, callback) => {
  //   const queryParams = JSON.parse(localStorage.getItem('queryParams'))
  //   console.log(queryParams)
  //  let x = queryParams.value.page
  //  console.log("fetch all " + currentPage.value)
  //  console.log("x " + x)
  let x = currentPage.value
    store
      .dispatch('app-services/fetchAll', {
        gender: gender.value,
        familyStatus: familyStatus.value,
        city: city.value,
        town: town.value,
        neighborhood: neighborhood.value,
        street:street.value,
        multi_weakness:multi_weakness.value,
        minDateReg: startDate.value,
        maxDateReg: endDate.value,
        service_status: statusFilter.value,
        service: serviceFilter.value,
        main_service: mainService.value,
        search: search.value,
        min_age: min_age.value,
        max_age: max_age.value,
        page_size: perPage.value,

        page: currentPage.value,
      })
      .then(response => {
        // console.log(" x = " + x)
        currentPage.value = x
        // console.log("currentPage.value" + currentPage.value)
       
        // console.log(response.data)
        const { data, total ,family_count} = response.data
        callback(data)
        totalUsers.value = total
        totalFamilies.value = family_count
      })
      .catch(() => {
        Vue.swal({
          title: 'حدثت مشكلة في استرجاع البيانات',
          icon: 'error',

          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
  }
  const succesStory = id => {
    store
      .dispatch('app-services/SucessStory', {
        id,
      })
      .then(response => {

        // toast(
        //   {
        //     component: ToastificationContent,
        //     props: {
        //       title: "تم تميزها كقصة نجاح ",
        //       icon: "StarIcon",
        //       variant: "success",
        //     },
        //   },
        //   {
        //     position: "top-center",
        //   }
        // );
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'حدثت مشكلة في استرجاع البيانات',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const fetchBenServices = (ctx, callback) => {
    store
      .dispatch('app-services/fetchBenServices', {
        gender: gender.value,
        familyStatus: familyStatus.value,
        city: city.value,
        town: town.value,
        neighborhood: neighborhood.value,
        street:street.value,
        multi_weakness:multi_weakness.value,
        minDateReg: startDate.value,
        maxDateReg: endDate.value,
        service_status: statusFilter.value,
        service: serviceFilter.value,
        main_service: mainService.value,
        search: search.value,
        min_age: min_age.value,
        max_age: max_age.value,
        page_size: perPage.value,

        page: currentPage.value,
      })
      .then(response => {
        const { data, total, family_count } = response.data.data
        datatest.value = response.data.data
        //  //console.log(datatest)
        callback(data)
        totalUsers.value = total
        totalFamilies.value = family_count
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'حدثت مشكلة في استرجاع البيانات',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'Information Manager') return 'success'
    if (role === 'Data Entry') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 0) return 'PauseIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'Data Entry') return 'Edit2Icon'
    if (role === 'Information Manager') return 'PauseIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'قيد الانتظار') return 'danger'
    if (status === 'مغلقة') return 'success'
    if (status === 'مفتوحة') return 'warning'
    return 'primary'
  }
  const donutChart = {
    series: [40, 60],
    chartOptions: {
      legend: {
        show: true,
        position: 'bottom',
        fontSize: '14px',
        fontFamily: 'Montserrat',
      },
      colors: [

      ],
      dataLabels: {
        enabled: true,
        formatter(val) {
          // eslint-disable-next-line radix
          return `${parseInt(val)}%`
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                fontSize: '2rem',
                fontFamily: 'Montserrat',
              },
              value: {
                fontSize: '1rem',
                fontFamily: 'Montserrat',
                formatter(val) {
                  // eslint-disable-next-line radix
                  return `${parseInt(val)}%`
                },
              },

            },
          },
        },
      },
      labels: ['الإناث', 'الذكور'],
      responsive: [
        {
          breakpoint: 992,
          options: {
            chart: {
              height: 380,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
        {
          breakpoint: 576,
          options: {
            chart: {
              height: 320,
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    name: {
                      fontSize: '1.5rem',
                    },
                    value: {
                      fontSize: '1rem',
                    },
                    total: {
                      show: true,
                      fontSize: '1rem',
                      label: 'Comments',
                      formatter() {
                        return '80%'
                      },
                    },
                  },
                },
              },
            },
            legend: {
              show: true,
            },
          },
        },
      ],
    },
  }

  return {
    donutChart,
    fileExcel,
    fetchBenServices,
    fetchAll,
    fetchBeneficiary,
    datatest,
    datastatistics,
    tableColumns,
    tableColumnsBen,
    perPage,
    currentPage,
    totalUsers,
    totalFamilies,
    dataMeta,
    perPageOptions,
    searchQuery,
    mainServiceList,
    mainServiceOption,
    sortBy,
    isSortDirDesc,
    refServiceListTable,
    reftransferListTable,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    ExcelDone,
    mainServiceOption,
    changeStatus,
    requestStatus,

    openStatus,
    succesStory,
    mainServiceList,
    // Extra Filters
    gender,
    familyStatus,
    city,
    town,
    street,
    neighborhood,
    multi_weakness,
    startDate,
    endDate,
    planFilter,
    statusFilter,
    mainService,
    serviceFilter,
    search,
    min_age,

    max_age,
    toast,
  }
}
