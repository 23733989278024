import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    AcceptService(id, payload) {
      return new Promise((resolve, reject) => {
      // console.log(payload)
      // console.log(id)
        axios
          .put(`/api/v1/person_service_refferals/${payload.id}/approve`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GetMainService() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/beneficiaries_page/get_dropdown_data')
          .then(response => resolve(response.data.data.main_services))
          .catch(error => reject(error))
      })
    },
    SubService(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/main_services/${id}/proposal_services`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getEmployy(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/main_services/${id}/employees`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchListServices(ctx, queryParams) {
      // console.log("queryParams",queryParams)
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/service_referrals', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    fetchBeneficiary(ctx, queryParams) {
    // console.log("queryParams",queryParams)
     return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/persons_to_all_users', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchAll(ctx, queryParams) {
     console.log("queryParams befor storage",queryParams)
      const now = new Date()
  
      // `item` is an object which contains the original value
      // as well as the time when it's supposed to expire
      const item = {
        value: queryParams,
        expiry: now.getTime() + 5,
      }

      localStorage.setItem('queryParams', JSON.stringify(item))
      
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/persons_to_all_users', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchstatistics(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/stats/persons_stats', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    setWithExpiry(key, value, ttl) {
      const now = new Date()

      // `item` is an object which contains the original value
      // as well as the time when it's supposed to expire
      const item = {
        value,
        expiry: now.getTime() + ttl,
      }
      localStorage.setItem(key, JSON.stringify(item))
    },
    fetchListrequestAuditing(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        // console.log(queryParams)
        axios
          .get('/api/v1/service_maintainer/coordinator/offered_services', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    fetchBenServices(ctx, queryParams) {
      // console.log("queryParams",queryParams)
      const now = new Date()

      // `item` is an object which contains the original value
      // as well as the time when it's supposed to expire
      const item = {
        value: queryParams,
        expiry: now.getTime() + 5,
      }
      localStorage.setItem('queryParams', JSON.stringify(item))
      return new Promise((resolve, reject) => {
        // let url=`/api/v1/service_maintainer/persons?gender=${queryParams.gender}&min_service_registration_date=${queryParams.minDate}&max_service_registration_date=${queryParams.maxDate}`
        // console.log(queryParams)
        axios
          .get('/api/v1/service_maintainer/persons_with_services', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    fetchListServicesInforamition(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        // let url=`/api/v1/service_maintainer/persons?gender=${queryParams.gender}&min_service_registration_date=${queryParams.minDate}&max_service_registration_date=${queryParams.maxDate}`
        // console.log(queryParams)
        axios
          .get('/api/v1/person_service_refferals?main_service_id=1', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    OpenStatus(ctx, { id }) {
      return new Promise((resolve, reject) => {
        const url = `/api/v1/service_management/${id}/open`
        axios
          .put(url)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    changeStatus(ctx, { id, statusid }) {
      return new Promise((resolve, reject) => {
        // console.log("statusid",statusid)
        // console.log("statssusid",id)

        const url = `/api/v1/information_manager/offered_services/${id}/change_status`
        axios
          .put(url, { to_status: statusid })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    responseByCoordinator(ctx, { id, approval_status, notes }) {
      return new Promise((resolve, reject) => {
        const url = `api/v1/service_maintainer/offered_services/${id}/respond-to-audit-request`
        axios
          .put(url, { approval_status, notes })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    SucessStory(ctx, { id }) {
      return new Promise((resolve, reject) => {
        const url = `/api/v1/service_management/${id}/toggle_success_story`
        axios
          .put(url)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    requestStatus(ctx, { id }) {
      return new Promise((resolve, reject) => {
        const url = `/api/v1/service_maintainer/offered_services/${id}/request-auditing`
        axios
          .put(url)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
